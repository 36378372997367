import { http } from './config'
import authHeader from './auth-header';

export default {
    lista: () => {
       
      const params = {
        'cemiterio': 'Previda',
    }
    // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
    return http.get('/obitos/lista_obituario', {
        params: params,
        headers: authHeader() 
  });
  },

}
